import React, { useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch';
import EditorDynamicDataWrapper from 'components/editor-data/EditorDynamicDataWrapper';
import Request from 'components/data/Request';
import { FieldSetFacebookGeographic } from './Channels/Facebook/FacebookGeographic';
import { FieldSetFacebookDetailedTargeting } from './Channels/Facebook/FacebookDetailedTargeting';
import { FieldSetFacebookGeneric } from './Channels/Facebook/FacebookGeneric';
import { FieldSetSnapchatGeneric } from './Channels/Snapchat/SnapchatGeneric';
import { FieldSetTikTokGeneric } from './Channels/TikTok/TikTokGeneric';
import { FieldSetTikTokGenericSelectMultiple } from './Channels/TikTok/TikTokSelectMultiple';
import { FieldSetCM360 } from './Channels/CM360';
import { FieldSetLinkedInTargetingFacets } from './Channels/LinkedIn/LinkedInTargetingFacets';
import { FieldSetLinkedInGetEngagementRules } from './Channels/LinkedIn/LinkedInGetEngagementRules';
import { FieldSetLinkedInLinkedInAdBudget } from './Channels/LinkedIn/LinkedInAdBudget';
import { FieldSetLinkedInBlockList } from './Channels/LinkedIn/LinkedInBlockList';
import { FieldSetLinkedInEvents } from './Channels/LinkedIn/LinkedInEvents';
import { FieldSetLinkedInGeneric } from './Channels/LinkedIn/LinkedInGeneric';
import { FieldSetSnapchatGeoLocations } from './Channels/Snapchat/SnapchatGeoLocations';
import { FieldSetSnapchatSelectMultiple } from './Channels/Snapchat/SnapchatSelectMultiple';
import { FieldSetGoogleAdsCampaigns } from './Channels/GoogleAds/GoogleAdsCampaigns';
import MetadataInputConfig from './data/config.json';
import { FieldSetLinkedInConversions } from './Channels/LinkedIn/LinkedInConversions';
import { FieldSetLinkedInSelectMultiple } from './Channels/LinkedIn/LinkedInSelectMultiple';
import { FieldSetCM360Generic } from './Channels/CM360/CM360Generic';
import { FieldSetPeachConnectGeneric } from './Channels/PeachConnect/PeachConnectGeneric';
import { FieldSetXGenericSelectMultiple } from './Channels/X/XSelectMultiple';
import { MetadataInputSelectType } from './types/metadataInputProps.types';

const METADATA_API_URL = process.env.METADATA_API_URL;

interface Props {
    data: {
        config: unknown;
        itemType: string;
        key: string;
        label: string;
        metadataType: string;
        model: string;
        type: string;
        selectType?: MetadataInputSelectType;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

/**
 * TODO: This can be removed when we have the new authentication service in use
 *
 * Requests a metadata token from the campaigndesigner api
 * @returns A JWT token that can be used to request metadata from the Platform metadata API
 */
const getMetadataToken = async () => {
    const {
        data: { token }
    } = await Request.post('/metadata/getToken', {});

    if (!token) throw new Error('401 no token found');

    return token;
};

/**
 * A wrapper component around every metadata input component. This component handles the request that is needed to fill the specific metadata input component.
 *
 * It does error handling, loading and returning the response. It makes sure that we do not have to rebuild this functionality for every metadata input field
 *
 */
export const MetaDataInput = (props: Props) => {
    const { data, ...genericProps } = props;
    const [q, setQ] = useState<string>('');
    const metadataInputConfig = MetadataInputConfig[data.metadataType];

    const { channel, endpoint, method } = MetadataInputConfig[data.metadataType];
    const [request, setRequest] = useState<{ params?: unknown; body?: unknown } | undefined>();

    const { error, loading, response, getData } = useFetch<unknown>(
        {
            url: METADATA_API_URL + channel + '/' + endpoint,
            data: request?.body,
            params: request?.params,
            method
        },
        getMetadataToken
    );

    useEffect(() => {
        if (!request?.body && !request?.params) return;
        // if the request object changes it sends a request to the platform metadata api to get new data
        getData();
    }, [JSON.stringify(request)]);

    if (!metadataInputConfig) {
        console.log('No metadata input config found for ' + data.metadataType);
        return null;
    }

    // Get the specified metadata input component
    const MetaDataInputComponent = MetaDataInputs[data.metadataType];

    // If metadata input component does not exist show proper message
    if (!MetaDataInputComponent) return console.log('Metadata input field of type ' + data.metadataType + ' not found');

    return (
        <MetaDataInputComponent
            {...genericProps}
            data={{ ...data }}
            additionalProps={{
                response,
                error,
                setRequest,
                q,
                setQ,
                metadataType: data.metadataType
            }}
        />
    );
};

const MetaDataInputs = {
    facebookGeographic: EditorDynamicDataWrapper(FieldSetFacebookGeographic),
    facebookAdAccount: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookCampaign: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookAdSet: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookAd: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookAssignedPages: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookPixel: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookDetailedTargeting: EditorDynamicDataWrapper(FieldSetFacebookDetailedTargeting),
    facebookCustomAudiences: EditorDynamicDataWrapper(FieldSetFacebookDetailedTargeting),
    facebookPage: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    instagramActorId: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookCatalog: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookProductSet: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookPost: EditorDynamicDataWrapper(FieldSetFacebookGeneric),
    facebookLeadGenForm: EditorDynamicDataWrapper(FieldSetFacebookGeneric),

    tiktokCampaign: EditorDynamicDataWrapper(FieldSetTikTokGeneric),
    tiktokAdGroup: EditorDynamicDataWrapper(FieldSetTikTokGeneric),
    tiktokAd: EditorDynamicDataWrapper(FieldSetTikTokGeneric),
    tiktokLocations: EditorDynamicDataWrapper(FieldSetTikTokGenericSelectMultiple),
    tiktokLanguages: EditorDynamicDataWrapper(FieldSetTikTokGenericSelectMultiple),
    tiktokInterestsAndBehaviors: EditorDynamicDataWrapper(FieldSetTikTokGenericSelectMultiple),
    tiktokDeviceModels: EditorDynamicDataWrapper(FieldSetTikTokGenericSelectMultiple),
    tiktokCarriers: EditorDynamicDataWrapper(FieldSetTikTokGenericSelectMultiple),
    tiktokAccount: EditorDynamicDataWrapper(FieldSetTikTokGeneric),
    tiktokAdAccount: EditorDynamicDataWrapper(FieldSetTikTokGeneric),

    cm360campaigns: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360placements: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360landingPages: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360ads: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360creatives: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360regions: EditorDynamicDataWrapper(FieldSetCM360),
    cm360platformTypes: EditorDynamicDataWrapper(FieldSetCM360),
    cm360postalCodes: EditorDynamicDataWrapper(FieldSetCM360),
    cm360advertisers: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360directorySites: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360sites: EditorDynamicDataWrapper(FieldSetCM360Generic),
    cm360accounts: EditorDynamicDataWrapper(FieldSetCM360),

    linkedInTargetingFacets: EditorDynamicDataWrapper(FieldSetLinkedInTargetingFacets),
    linkedInTargetingEntities: EditorDynamicDataWrapper(FieldSetLinkedInSelectMultiple),
    linkedInGetEngagementRules: EditorDynamicDataWrapper(FieldSetLinkedInGetEngagementRules),
    linkedInBlockList: EditorDynamicDataWrapper(FieldSetLinkedInBlockList),
    linkedInEvents: EditorDynamicDataWrapper(FieldSetLinkedInEvents),
    linkedInAdBudget: EditorDynamicDataWrapper(FieldSetLinkedInLinkedInAdBudget),
    linkedInAdAccount: EditorDynamicDataWrapper(FieldSetLinkedInGeneric),
    linkedInCampaignGroup: EditorDynamicDataWrapper(FieldSetLinkedInGeneric),
    linkedInCampaign: EditorDynamicDataWrapper(FieldSetLinkedInGeneric),
    linkedInConversion: EditorDynamicDataWrapper(FieldSetLinkedInConversions),

    googleAdsCampaigns: EditorDynamicDataWrapper(FieldSetGoogleAdsCampaigns),

    snapchatGeoLocation: EditorDynamicDataWrapper(FieldSetSnapchatGeoLocations),
    snapchatInterest: EditorDynamicDataWrapper(FieldSetSnapchatSelectMultiple),
    snapchatDemographic: EditorDynamicDataWrapper(FieldSetSnapchatSelectMultiple),
    snapchatDevice: EditorDynamicDataWrapper(FieldSetSnapchatSelectMultiple),
    snapchatCampaign: EditorDynamicDataWrapper(FieldSetSnapchatGeneric),
    snapchatAdSquad: EditorDynamicDataWrapper(FieldSetSnapchatGeneric),
    snapchatAd: EditorDynamicDataWrapper(FieldSetSnapchatGeneric),
    snapchatPixel: EditorDynamicDataWrapper(FieldSetSnapchatGeneric),
    snapchatAdAccount: EditorDynamicDataWrapper(FieldSetSnapchatGeneric),
    snapchatPublicProfile: EditorDynamicDataWrapper(FieldSetSnapchatGeneric),

    peachconnectLookups: EditorDynamicDataWrapper(FieldSetPeachConnectGeneric),

    xLocation: EditorDynamicDataWrapper(FieldSetXGenericSelectMultiple)
};
