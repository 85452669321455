import React, { useEffect, useMemo } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataAutocomplete } from 'components/input/Metadata/shared';
import { SnapchatGenericMetaData, SnapchatGenericMetaDataResponse, SnapchatItem } from '../types/types';
import { ItemNameMapping } from '../helpers/item-name-mapping';
import { PropertyMapping } from '../helpers/properties-mapping';

interface Props extends MetadataInputProps {
    response?: SnapchatGenericMetaDataResponse;
    config: {
        accountId: string;
        location_types: string[];
    };
    value: string;
    readOnly?: boolean;
}

/**
 * Facebook geographic metadata input component
 *
 */
const SnapchatGeneric = ({ response, config, value, onMutation, setRequest, metadataType, readOnly }: Props) => {
    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config
            }
        });
    }, [JSON.stringify(config)]);

    const getOptions = (response: SnapchatGenericMetaDataResponse | undefined): SnapchatItem[] => {
        if (!response) return [];
        return response.data.map((item: SnapchatGenericMetaData) => {
            const itemName = ItemNameMapping[metadataType];
            const MetadataTypeProps = PropertyMapping[metadataType];
            const result = item[itemName];
            if (!MetadataTypeProps) return result;

            return { id: result[MetadataTypeProps.id], name: result[MetadataTypeProps.name] };
        });
    };

    const options = useMemo(() => getOptions(response), [response]);

    if (!options) return;

    return <MetadataAutocomplete readOnly={readOnly} options={options} value={value} onMutation={onMutation} />;
};

export default SnapchatGeneric;
